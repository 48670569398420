*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.line {
    position: relative;
    /* font-size: 18px; */
}

.line::after {
    content: "";
    position: absolute;
    background-color: #052e69;
    height: 4px;
    width: 0%;
    left: 0;
    bottom: 20px;
    transition: all 0.3s;
    text-decoration: none;
}

.line:hover::after{
    width: 100%;
}