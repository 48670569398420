
.services-section {
    /* background: #F9423D; */
    background-image: linear-gradient(to bottom right, rgba(0, 0, 0, 0.9) 0%, transparent 100%),
                      url(../../images/cargo.jpg);
    width: auto;
    height: auto;
    min-height: 100vh;
    padding-bottom: 8rem;
    z-index: 1;
}

@media screen and (max-width: 3840px) {
    .services-section {
        width: 3820px;
        height: auto;
        background-size: 3820px;
    }
  }

@media screen and (max-width: 2560px) {
    .services-section {
        width: 2560px;
        height: auto;
        background-size: 2560px;
    }
  }

  @media screen and (max-width: 2048px) {
    .services-section {
        width: 2028px;
        height: auto;
        background-size: 2028px;
    }
  }


  @media screen and (max-width: 1920px) {
    .services-section {
        width: 1900px;
        height: auto;
        background-size: 1920px;
    }
  }

  @media screen and (max-width: 1366px) {
    .services-section {
        width: 1346px;
        height: auto;
        background-size: 1366px;
    }
  }

  @media screen and (max-width: 1280px) {
    .services-section {
        width: 1260px;
        height: auto;
        background-size: 1280px;
    }
  }

  @media screen and (max-width: 780px) {
    .services-section {
        width: auto;
        height: auto;
        background-size: auto;
    }
  }

.services-heading {
    color: #fff;
    text-align: center;
    margin-bottom: 2rem;
    padding-top: 3rem;
}

.services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 2590px;
    margin: 0 auto;
    transform: translateY(80px);
    padding: 0;
    background-color: transparent;
    height: auto;
    margin-bottom: -60px;
}

.services-cell {
    /* flex: 0 1 250px; */
    width: 350px;
    height: 400px;
    margin: 28px;
    top: -100px;
    position: relative;
    text-align: center;
    z-index: 1;
    box-shadow: rgb(248, 248, 248);
    background-color: rgb(248, 248, 248);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    cursor: pointer;
    
}

@media screen and (max-width: 3840px) {
    .services-cell {
        width: 850px;
        height: 900px;
        top: -60px;
        margin: 28px;
    }
  }

@media screen and (max-width: 2560px) {
    .services-cell {
        width: 600px;
        height: 650px;
        top: -10px;
        margin: 28px;
    }
  }

  @media screen and (max-width: 2048px) {
    .services-cell {
        width: 600px;
        height: 650px;
        top: -30px;
        margin: 28px;
    }
  }

@media screen and (max-width: 1920px) {
    .services-cell {
        width: 450px;
        height: 500px;
        top: -10px;
        margin: 28px;
    }
  }

  @media screen and (max-width: 1366px) {
    .services-cell {
        width: 350px;
        height: 400px;
        top: -100px;
        margin: 28px;
    }
  }

  @media screen and (max-width: 1280px) {
    .services-cell {
        width: 320px;
        height: 370px;
        top: -100px;
        margin: 28px;
    }
  }

@media screen and (max-width: 760px) {
    .services-cell {
        width: 290px;
        height: 315px;
        top: -80px;
    }
  }
  
.services-cell_img {
    position: absolute;
    top: 63px;
    left: 0;
    width: 50%;
    height: 66%;
    object-fit: cover;
}

.services-cell_text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 20px;
    text-align: center;
    background: linear-gradient(45deg, #052e69, rgba(63, 192, 252,0.2));
    color: #fff;
    opacity: 1;
    transition: 0.5s;
    font-family: 'Kanit', sans-serif;
}

.h2 {
    text-align: center;
    line-height: 22px;
    margin-bottom: 15px;
}

@media screen and (max-width: 3840px) {
    .h2 {
        font-size: 60px;
        line-height: 60px;
    }
  }

@media screen and (max-width: 2560px) {
    .h2 {
        font-size: 42px;
        line-height: 38px;
    }
  }

@media screen and (max-width: 1920px) {
    .h2 {
        font-size: 34px;
        line-height: 30px;
    }
  }

  @media screen and (max-width: 1366px) {
    .h2 {
        font-size: 28px;
        line-height: 30px;
    }
  }

  @media screen and (max-width: 1280px) {
    .h2 {
        font-size: 26px;
        line-height: 28px;
    }
  }

@media screen and (max-width: 760px) {
    .h2 {
        font-size: 20px;
        text-align: left;
    }
  }

.ptag {
    text-align: left;
}

@media screen and (max-width: 760px) {
    .ptag {
        font-size: 12px;
    }
  }

  @media screen and (max-width: 3840px) {
    .services-cell_text {
        font-size: 46px;
        line-height: 54px;
    }
  }

  @media screen and (max-width: 2560px) {
    .services-cell_text {
        font-size: 30px;
        line-height: 38px;
    }
  }

  @media screen and (max-width: 1920px) {
    .services-cell_text {
        font-size: 22px;
        line-height: 36px;
    }
  }

  @media screen and (max-width: 1366px) {
    .services-cell_text {
        font-size: 16px;
        line-height: 22px;
    }
  }

  @media screen and (max-width: 1280px) {
    .services-cell_text {
        font-size: 16px;
        line-height: 20px;
    }
  }

.services-cell::before, .services-cell::after, .services-cell_img {
    position: absolute;
    top: 63px;
    left: 0;
    width: 100%;
    height: 66%;
    object-fit: cover;
}

.services-cell:hover {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    transform: scale(1.2);
    z-index: 99;
    overflow: hidden;
}

.services-cell:hover::before {
    transform: translate(-100px, 600%) rotate(45deg);
    transition: transform 0.5s;
}

.ptag {
    text-align: left;
}

.red {
    color: #cb0c0d;
}