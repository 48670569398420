*,
*::before,
*::after {
  box-sizing: border-box; 
}

.row {
  width: 100%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
} 

.lineAbout1 {
  position: relative;
  /* font-size: 18px; */
}

.lineAbout1::before {
  content: "";
  position: absolute;
  background-color: #052e69;
  height: 5px;
  width: 100%;
  left: 0;
  bottom: -4px;
  text-decoration: none;
}

.lineAbout2 {
  position: relative;
  /* font-size: 18px; */
}

.lineAbout2::before {
  content: "";
  position: absolute;
  background-color: #cb0c0d;
  height: 5px;
  width: 100%;
  left: 0;
  bottom: -4px;
  text-decoration: none;
}

.lineAbout3 {
  position: relative;
  /* font-size: 18px; */
}

.lineAbout3::before {
  content: "";
  position: absolute;
  
  text-decoration: none;
}



/* --------------------------------------------- LIST TRUCK ----------------------------------------------------*/



.gallery {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.gallery .image {
    padding: 7px;
    width: calc(100% / 3);
}

.gallery .image.hide {
    display: none;
}

.gallery .image.show {
    display: block;
}

.gallery .image span {
    display: flex;
    width: 100%;
    overflow: hidden;
}
.gallery .image img {
    width: 100%;
    vertical-align: middle;
    transition: all 0.3s ease;
}

.gallery .image:hover img {
    transform: scale(1.1);
}


@media(max-width: 1000px) {
    .gallery .image {
        width: calc(100% / 2);
    }
}

@media(max-width: 700px) {
    nav .items {
        max-width: 600px;
    }
    nav .items .item {
        padding: 7px 15px;
    }
}

@media(max-width: 600px) {
    nav .items {
        flex-wrap: wrap;
        justify-content: center;
    }
    nav .items .item {
        margin: 5px;
    }
    .gallery .image {
        width: 100%;
    }
}
