*,
*::before,
*::after {
  box-sizing: border-box; 
}

.row {
  width: 100%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.lineAbout1 {
  position: relative;
  /* font-size: 18px; */
}

.lineAbout1::before {
  content: "";
  position: absolute;
  background-color: #052e69;
  height: 5px;
  width: 100%;
  left: 0;
  bottom: -4px;
  text-decoration: none;
}

.lineAbout2 {
  position: relative;
  /* font-size: 18px; */
}

.lineAbout2::before {
  content: "";
  position: absolute;
  background-color: #cb0c0d;
  height: 5px;
  width: 100%;
  left: 0;
  bottom: -4px;
  text-decoration: none;
}

.lineAbout3 {
  position: relative;
  /* font-size: 18px; */
}

.lineAbout3::before {
  content: "";
  position: absolute;
  
  text-decoration: none;
}


/* --------------------------------------------- LIST PARTNER ----------------------------------------------------*/



.galleryW {
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  margin-bottom: 70px;
}

.galleryW .imageW {
  padding: 7px;
  width: calc(100% / 4);
}

.galleryW .imageW.hide {
  display: none;
}

.galleryW .imageW.show {
  display: block;
}

.galleryW .imageW span {
  display: flex;
  width: 100%;
  overflow: hidden;
}
.galleryW .imageW img {
  width: 100%;
  vertical-align: middle;
  transition: all 0.3s ease;
}


@media(max-width: 1000px) {
  .galleryW .imageW {
      width: calc(100% / 2);
  }
}


@media(max-width: 600px) {
  .galleryW .imageW {
      width: 100%;
  }
}
