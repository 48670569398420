.dropdown-menu {
    width: 200px;
    position: absolute;
    top: 80px;
    list-style: none;
    text-align: start;
}

.dropdown-menu li {
    background: #052e69;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background: #fafbfc;
    transition: 0.6s ease-in-out;
}

.dropdown-menu.clicked {
    display: none;
}

.dropdown-link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
}

.dropdown-link:hover {
    color: #052e69;
    /* transition: 0.3s ease-out; */
}