*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.lineAbout1 {
    position: relative;
    /* font-size: 18px; */
}

.lineAbout1::before {
    content: "";
    position: absolute;
    background-color: #052e69;
    height: 5px;
    width: 100%;
    left: 0;
    bottom: -4px;
    text-decoration: none;
}

.lineAbout2 {
    position: relative;
    /* font-size: 18px; */
}

.lineAbout2::before {
    content: "";
    position: absolute;
    background-color: #cb0c0d;
    height: 5px;
    width: 100%;
    left: 0;
    bottom: -4px;
    text-decoration: none;
}


.card-item1 {
    margin-left: 0px;
}

.card-item2 {
    margin-left: 19px;
}


/* ------------------------------------------PARTNER----------------------------------------------------- */

.galleryA {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.galleryA .imageA {
    padding: 27px;
    width: calc(100% / 2);
}

.galleryA .imageA.hide {
    display: none;
}

.galleryA .imageA.show {
    display: block;
}

.galleryA .imageA span {
    display: flex;
    width: 100%;
    overflow: hidden;
}
.galleryA .imageA img {
    width: 100%;
    vertical-align: middle;
}



@media(max-width: 1000px) {
    .galleryA .imageA {
        width: calc(100% / 2);
    }
}

@media(max-width: 700px) {
    nav .items {
        max-width: 600px;
    }
    nav .items .item {
        padding: 7px 15px;
    }
}

@media(max-width: 600px) {
    nav .items {
        flex-wrap: wrap;
        justify-content: center;
    }
    nav .items .item {
        margin: 5px;
    }
    .galleryA .imageA {
        width: 100%;
    }
}
